* {
  box-sizing: border-box;
  font-family: Georgia, Times, 'Times New Roman', serif;
  scroll-behavior: smooth;
}

body{
  margin: 0;
  padding: 0;
}
