

.FileExplorer {
  background-color: #000000;
  color: black;
  position: relative;
  width: 900px;
  height: 600px;
  margin: 100px;
}

.FileTop {
  background-color: #202020;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: white;
  position: absolute;
  width: 900px;
  height: 50px;
  margin-top: 30px;
}

.FileTop h1 {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Inherit or explicitly define the font family */
  color: white; /* Ensure text color is white */
  font-size: 24px; /* Define an appropriate font size */
  margin: 0; /* Remove any default margin to align properly within the container */
  padding: 0; /* Adjust padding as needed, zero here assuming full use of the container's height */
  line-height: 50px; /* Set line height to match the container's height for vertical centering */
}

.ExitButton {
  position: absolute;
  right: 0;
}

.FullScreenButton {
  position: absolute;
  right: 50px;
}

.FileSideBar {
  background-color: #191919;
  position: absolute;
  width: 150px;
  height: 480px;
  margin-top: 100px;
  color: #DEDEDE
}

.FileSideBar > * {
  margin: 10px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.FileSpace {
  background-color: #202020;
  position: absolute;
  left: 150px;
  top: 100px;
  width: 750px;
  height: 500px;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 0px;
}

.FileBottom {
  background-color: #333333;
  height: 20px;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.File {
  position: relative; /* Needed for absolute positioning of children */
  width: 100px;
  height: 150px;
  margin: 25px;
  background-image: url("../assets/foldericon.png");
  background-size: calc(100% - 30px);
  background-repeat: no-repeat; /* Prevents the icon from repeating inside the div */
  background-position: center;
  display: flex; /* Enables the use of flex properties for alignment */
  flex-direction: column; /* Stacks children vertically */
  justify-content: flex-end; /* Aligns children at the bottom */
}

.ProjectName {
  position: absolute;
  color: white;
  font-size: small;
  bottom: -20; /* Ensures it's at the bottom */
  width: 100%; /* Ensures it spans the entire width of the .File div */
  text-align: center; /* Centers the text horizontally */
  padding: 2px 0; /* Optional: adds padding to avoid text touching the edges */
}

.Modal {
  position: fixed; /* Fixed positioning relative to the viewport */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex; /* Flexbox to center the content */
  align-items: center; /* Aligns vertically center */
  justify-content: center; /* Aligns horizontally center */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000; /* Makes sure the modal is on top of other content */
}

.ModalContent {
  background-image: url("../assets/openfile.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  padding: 0;
  border-radius: 0;
  width: 70%; /* Adjusted to fill the modal */
  height: 80%;
  display: flex; /* Added to align children side by side */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


.LeftPage {
  width: 40%; /* Each page takes up half the width of the ModalContent */
  margin-right: 10%;
  height: 100%;
  padding: 20px; /* Optional padding for content */
}

.RightPage {
  width: 45%; /* Each page takes up half the width of the ModalContent */
  height: 100%;
  padding: 20px; 
  overflow: auto;/* Optional padding for content */
}

.ModalProjectName {
  font-size: 30px;
  padding: 0px;
  position: absolute;
  color: black;
  width: 50%;
  margin: 0;
  top: 30px;
  left: 60px;
}

.ProjectDescription {
  padding: 0px;
  position: absolute;
  margin: 0;
  width: 400px;
  top: 80px;
  left: 60px;
  font-size: 18px;
}

.lastUpdated {
  padding: 0px;
  color: black;
  position: absolute;
  margin: 0;
  bottom: 30px;
  left: 60px;
  font-size: 12px;
}

.ProjectLink {
  background-color: #007BFF;  /* A typical "link" blue */
  color: white;               /* White text color */
  padding: 5px 10px;         /* Appropriate padding for a button */
  position: absolute;
  margin: 0;
  bottom: 30px;
  right: 100px;
  font-size: 16px;            /* Slightly larger font size for readability */
  border: none;               /* No border */
  border-radius: 5px;         /* Rounded corners */
  text-align: center;         /* Center text inside the button */
  text-decoration: none;      /* No underline */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  /* Subtle shadow for 3D effect */
  transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition for hover effects */
}

.ProjectLink:hover, .ProjectLink:focus {
  background-color: #0056b3;  /* Darker blue when hovered or focused */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Slightly larger shadow on hover for a "lifting" effect */
  cursor: pointer;            /* Change cursor to pointer to indicate it's clickable */
  text-decoration: none;      /* Ensure no underline on hover */
}


.CloseButton {
  position: absolute; /* Keep the button positioned relative to its parent container */
  top: 5px; /* Position from the top of the container */
  right: 20px; /* Position from the right of the container */
  border: none; /* No border for a cleaner look */
  background-color: #ff6347; /* No background color initially */
  opacity: .001;
  color: #333; /* Set a neutral or dark color for the '×' symbol */
  font-size: 24px; /* Make the '×' symbol large enough to be easily clickable */
  cursor: pointer; /* Change the cursor to a pointer to indicate it's clickable */
  width: 36px; /* Set a specific width */
  height: 36px; /* Set a specific height */
  line-height: 36px; /* Center the '×' vertically */
  text-align: center; /* Center the '×' horizontally */
  border-radius: 50%; /* Optional: Makes the button circular */
  opacity: 0.7; /* Set opacity for a softer look, enhancing UX */
  transition: background-color 0.3s, opacity 0.3s; /* Smooth transition for hover effects */
}

.CloseButton:hover, .CloseButton:focus {
  background-color: #ff6347; /* A warm color on hover suggests a close action */
  color: white; /* Change text color to white for better visibility on hover */
  opacity: 1; /* Full opacity on hover for emphasis */
}

.ProjectDescription {
  color: black;
}

.ReadmeContent {
  position: absolute; /* Positioning relative to the nearest positioned ancestor (ModalContent) */
  top: 100px; /* Adjust top spacing */
  left: 100px; /* Adjust right spacing */
  width: 33%;
  border: none;
  background: none;
  font-size: 12px;
  cursor: pointer;
}

.ListButton {
  display: flex;        /* Enable flexbox */
  align-items: center;  /* Center content vertically */
  justify-content: center;
  background-color: red;
  position: absolute;
  width: 17px;
  height: 17px;
  margin-right: 10px;;
  right: 0;
}

.ThumbnailsButton {
  display: flex;        /* Enable flexbox */
  align-items: center;  /* Center content vertically */
  justify-content: center;
  background-color: green;
  position: absolute;
  margin-right: 20px;
  width: 17px;
  height: 17px;
  right: 20px;
}

.ProjectCount {
  display: flex;        /* Enable flexbox */
  align-items: center;  /* Center content vertically */
  justify-content: center; /* Center content horizontally (already included for clarity) */
  
  
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #DEDEDE;
  position: absolute;
  margin-left: 5px;
  font-size: x-small;
  text-align: center;
  width: 70px;
  left: 10px;
  height: 100%;
}