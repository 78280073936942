.Page{
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    text-align: center; 
    border: 30px;
    color: white;
    background-color:#5d88bb;
    letter-spacing: .3px;
}

.Headshot {
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    max-width: 100%; 
    max-height: 100%;
}

.Resume {
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    height: 800px;
    width: 600px;
}

.headshot-image {
    object-fit: cover;
    height: 400px;
    width: 300px;
    border-radius: 10%;
    object-position: 50% 0%;
    object-fit: cover;
    padding: 5px;
    background-color: #b3cbe4;
}

@media (max-width: 683px) {
    .Intro {
        padding-top: 205px;
    }
}
.Intro {
    padding: 20px;
    background-color: #102336;
}
.Intro p, h1{
    text-align: center;
    border: 0;
    margin: 10px;
}

.AboutMe {
    display: flex;         /* Use flexbox to arrange child elements */
    flex-direction: column; /* Stack children vertically */
    align-items: center;   /* Center-align the flex items horizontally */
    justify-content: center; /* Center content vertically if there is extra space */
    text-align: center;    /* Ensure text within is centered */
    padding: 20px;
    background-color: #102336;
}

.aboutmeTitle h2 {
    margin: 0 0 10px 0;  /* Remove default margin, add small space below */
}

.blurb {
    max-width: 30%;       /* Limit width of the description to enhance readability */
    margin-bottom: 20px;  /* Space at the bottom if needed */
}

.Projects {
    display: flex;
    min-height: 300px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
}


